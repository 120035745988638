<template>
  <b-modal
    id="training-categories"
    title="Training categories"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    ><b-form @submit.prevent="update_available_categories">
      <b-table
        v-show="!loading"
        :fields="fields"
        :items="available_categories"
        sticky-header="50vh"
        head-variant="none"
        thead-class="d-none"
        ref="addTrainingcategories"
        responsive
      >
        <b-thead></b-thead>
        <template #cell(name)="data">
          {{ data.item }}
        </template>
        <template #cell(delete)="data">
          <button
            class="no-style-button float-right"
            @click="delete_category(data.index)"
          >
            <b-icon-x font-scale="2"></b-icon-x>
          </button>
        </template>
      </b-table>

      <b-form @submit.prevent="add_category">
        <b-form-input
          required
          v-model="category"
          placeholder="Add New Training Category & Press Enter"
        >
        </b-form-input>
        <br />

        <button type="submit" class="text-primary no-style-button">
          <u>+ Add Category </u>
        </button>
      </b-form>
      <hr />
      <div class="container-fluid" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div class="container-fluid" v-if="!loading">
        <b-button
          @click="close_modal"
          class="float-left"
          variant="outline-primary"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="!updated && available_categories.length === 0"
          class="float-right"
          type="submit"
          variant="primary"
        >
          Save Changes
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import {
  capitalize,
  add_category,
  update_list,
  remove_duplicates,
  update_available_categories,
  delete_category,
  close_modal,
} from "./actions";
export default {
  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        "/training"
      );
    },
  },
  data() {
    return {
      category: "",
      success: false,
      loading: false,
      error: false,
      updated: false,
      available_categories: [...new Set(this.categories)],
      fields: [
        { key: "name", label: "" },
        { key: "delete", label: "" },
      ],
    };
  },
  methods: {
    capitalize,
    add_category,
    update_list,
    remove_duplicates,
    update_available_categories,
    delete_category,
    close_modal,
  },
  mounted() {
  },
};
</script>
<style>


.hidden_header {
  display: none;
}
</style>
