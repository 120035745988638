import { axiosApiInst } from "@/plugins/axios";
import moment from "moment";

function create_training() {

  this.loading=true;
  axiosApiInst({
    url:this.url+'/',
    method: "post",
    data:this.training,
  })
    .then((res) => {
      console.log(res.status);
      this.loading = false;
      this.$emit('Successful');
      this.$bvToast.toast(res.data.message, {
        title: "Successfully Added!",
        toaster: "b-toaster-bottom-center",
        variant: "success",
        solid: true,
      });
      this.$bvModal.hide('add-training');

    })
    .catch((error) => {
      this.loading = false;
      this.$emit('Successful');
      this.$bvToast.toast(error, {
        title: "Failed to Save!",
        toaster: "b-toaster-bottom-center",
        variant: "danger",
        solid: true,
      });
    });
}
function format_num(num){
  return num?Number(num).toLocaleString():'-'
}



function time_format(time) {
  return moment(time,"YYYY-MM-DD HH:mm:ss").format("LL");
}
export { 
  create_training, 
  time_format,
  format_num,
};
