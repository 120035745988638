import { axiosApiInstance } from "@/plugins/axios";
function add_category() {
  let word = this.capitalize(this.category);
  this.available_categories = this.update_list(this.available_categories, word);
  this.category = "";
  this.updated = true;
}
function capitalize(word) {
  return word
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    .trim();
}
function update_list(list, word) {
  list.push(word);
  list = [...new Set(list)];
  return list;
}

function remove_duplicates(list) {
  return [...new Set(list)];
}
function delete_category(index) {
  this.available_categories.splice(index, 1);
  this.updated=true;
}

function close_modal() {
  this.$bvModal.hide("training-categories");
}
function update_available_categories() {
  if (this.updated && this.available_categories.length > 0) {
    this.loading = true;
    axiosApiInstance({
      url: this.url + "/categories",
      method: "put",
      data: this.available_categories,
    })
      .then((res) => {
        this.$bvToast.toast(res.data.message, {
          title: "Success!",
          toaster: "b-toaster-bottom-center",
          variant: "success",
          solid: true,
        });

        this.loading = false;
        this.$emit('Successful');
        this.$bvModal.hide('training-categories');
      })
      .catch((error) => {
        this.loading = false;
        this.$emit('Successful');

        this.$bvToast.toast("Failed to Update!" + error, {
          title: "Error",
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          solid: true,
        });
      });
  } else {
    this.$bvToast.toast("Please make some changes to the categories list", {
      title: "No Updates to save",
      toaster: "b-toaster-bottom-center",
      variant: "danger",
      solid: true,
    });
  }
}
export {
  update_available_categories,
  update_list,
  delete_category,
  remove_duplicates,
  capitalize,
  add_category,
  close_modal,
};
