<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">
        <b-link class="text-dark text-black" to="/training">TRAINING</b-link>
      </b-navbar-brand>
      <b-navbar-nav v-if="!loading" class="ml-auto">
        <b-button
          v-if="can('modify', 'training')"
          squared
          variant="outline-primary"
          v-b-modal.training-categories
          @click="edit_category"
        >
          <b-icon-gear></b-icon-gear> Training Categories
        </b-button>

        <div class="pl-3">
          <b-button
            v-if="can('modify', 'training')"
            squared
            variant="primary"
            v-b-modal.add-training
          >
            <b-icon-plus></b-icon-plus>New Training
          </b-button>
        </div>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <div class="d-flex col">
          <b-select
            class="form-control form-select mr-2"
            variant="light"
            placeholder="Select Training Category"
            v-model="criteria.category"
            :options="filter_categories"
            v-analytics:change="[
              'filter_training_category',
              { element: 'Training' },
            ]"
          >
          </b-select>
          <b-form class="ml-auto-">
            <b-form-input
              placeholder="Search Name"
              type="search"
              class="search m-0"
              v-analytics:change="[
                'search_training_session',
                { element: 'Training' },
              ]"
              v-model="criteria.searchfield"
            ></b-form-input>
          </b-form>
          <produce-date-picker
          class="pl-2"
            @dates-selected="cfilterByDate"
          >
          </produce-date-picker>
        </div>
        <div class="col-auto">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="get_training_sessions">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" v-print="'#TrainingTable'">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <!-- <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button> -->
            <download-excel
              class="btn-opt btn primary"
              :data="items"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <b-container v-show="loading">
        <b-row class="d-flex justify-content-center p-3">
          <b-spinner
            style="width: 3rem height: 3rem"
            label="Large Spinner"
          ></b-spinner>
        </b-row>
      </b-container>
      <b-container class="text-center" v-if="items.length === 0 && !loading">
        <p class="text-muted pt-3">
          You have no training Sessions. Click to add a training Session.
        </p>
        <b-button variant="outline-primary" v-b-modal.add-training>
          Add Training
        </b-button>
      </b-container>
      <div v-show="items.length > 0">
        <b-table
          id="TrainingTable"
          :fields="fields"
          :items="items"
          :filter="criteria"
          sticky-header="75vh"
          head-variant="light"
          hover
          @row-clicked="view_training"
          :filter-function="filterItems"
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
        >
          <template #cell(attended)="data" class="text-right">
            <span class="float-left">
              {{ data.item.attendee_no }}
            </span>
            <span class="float-right">
              <b>{{ data.item.attendee_percentage }}%</b>
            </span>
          </template>

          <template #cell(date)="data">
            <span class="font-weight-bold small">
              {{ format_date(data.item.start_date) }}
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <categories
      @Successful="reload()"
      :key="category_modal"
      :categories="categories_for_edit"
    ></categories>
    <add-training
      @Successful="reload()"
      :key="add_training"
      :districts="districts"
      :categories="available_categories"
    ></add-training>
  </div>
</template>

<script>
import {
  get_training_sessions,
  get_training_categories,
  format_date,
  view_training,
  get_districts,
} from "./actions.js";
import { can } from "@/modules/auth/utils.js";
import Categories from "./components/categories/Categories.vue";
import AddTraining from "./components/add_training/AddTraining.vue";
import { Periods } from "../../../produce/action.js";
import ProduceDatePicker from "../../../produce/components/ProduceDatePicker.vue";
import dateMixin from "../../../produce/date_mixin";
import moment from "moment";
export default {
  components: { Categories, AddTraining, ProduceDatePicker },
  mixins: [dateMixin],
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    url() {
      return this.baseUrl + this.$store.state.project_id + "/training";
    },
  },
  data() {
    let fields = [
      "category",
      "topic",
      "target",
      { key: "attended" },
      "date",
      "location",
    ];
    let schedule_data = {
      load: true,
    };
    return {
      fields,
      isAdding: false,
      items: [],
      category_modal: 0,
      add_training: 100,
      loading: false,
      crops: [],
      alltrainings: [],
      filter_categories: [],
      available_categories: [],
      categories_for_edit: [],
      districts: [],
      schedule_data,
      selectedPeriod: "",
      criteria: {
        category: null,
        searchfield: null,
        selectedPeriod: Periods[""],
      },
    };
  },

  mounted() {
    this.get_training_sessions();
    this.get_training_categories();
    this.get_districts();
    this.dateInit();
  },

  methods: {
    view_training,
    get_training_sessions,
    get_training_categories,
    format_date,
    can,
    get_districts,
    edit_category() {
      this.category_modal++;
    },
    filterItems(row, criteria) {
      let filters = [];
      if (criteria.category) {
        filters.push(row["category"] == criteria.category);
      }
      if (criteria["selectedPeriod"]) {
        filters.push(
          moment(row["start_date"], "YYYY-MM-DD").isBetween(
            ...this.criteria.selectedPeriod
          )
        );
      }
      if (criteria["searchfield"]) {
        filters.push(
          row["topic"]
            .toLowerCase()
            .includes(criteria["searchfield"].toLowerCase())
        );
      }
      return !filters.includes(false);
    },
    reload() {
      this.get_training_sessions();
      this.add_training++;
      this.category_modal++;
      this.get_training_categories();
    },
  },
};
</script>

<style lang="scss" scoped></style>
