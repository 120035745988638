<template>
  <b-modal
    :id="modal"
    title="New Training"
    size="lg"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-container v-if="categories.length === 0">
      <p class="text-muted">To Proceed, Click to Add training Categories</p>
      <p class="text-muted">Be sure to save.</p>
      <b-button v-b-modal.training-categories variant="outline-primary"
        >Add categories</b-button
      >
    </b-container>
    <training-form
      :loading="loading"
      :training="training"
      :districts="districts"
      :categories="categories"
      :submit_action="submit_action"
      :modal="modal"
      @Submit="create_training"
    >
    </training-form>
  </b-modal>
</template>
<script>
import { time_format, create_training } from "./actions.js";

import TrainingForm from "@/modules/training/components/training_form/TrainingForm.vue";

export default {
  components: { TrainingForm },

  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    districts: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        "/training"
      );
    },
  },

  data() {
    return {
      loading: false,
      success: false,
      error: false,
      currency: "UGX. ",

      training: {
        category: "",
        topic: "",
        trainers: [],
        district: "",
        location: "",
        start_date: "",
        duration: 0,
        target: 0,
      },
      submit_action: "Create Training",
      modal: "add-training",
    };
  },

  methods: {
    time_format,
    create_training,
  },
  mounted() {},
};
</script>
<style scoped>
@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.password {
  font-family: "password";
}
.badge-primary {
  background-color: #3f7bb6 !important;
}
.small-button {
  width: 0;
}
.min40 {
  min-width: 120px;
}
</style>
